import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AUTH_ROLE } from 'constants/AuthConstant';

const PublicRoute = () => {
  const role = localStorage.getItem(AUTH_ROLE); // Get role
  const { token } = useSelector((state) => state.auth); // Check for token

  if (token) {
    // Redirect based on role
    if (role === 'account') {
      return <Navigate to="/product" replace />;
    }
    return <Navigate to="/" replace />;
  }

  return <Outlet />; // Render public routes
};

export default PublicRoute;
